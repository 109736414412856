import { defineStore } from "pinia";
import type { UserAnsweringSessionEntity } from "~/types/userAnsweringSessionEntity";
import { useResultStore } from "~/store/result.store";
import { SessionStatus } from "~/types/sessionStatus";

interface State {
  session: UserAnsweringSessionEntity | null;
  sessionStatus: SessionStatus;
  isDeadlinePassed: boolean;
}

export const useSessionStore = defineStore("session", {
  state: (): State => ({
    session: null,
    isDeadlinePassed: false,
    sessionStatus: SessionStatus.NONE,
  }),
  actions: {
    async start() {
      const api = useApi();
      const sessionEither = await api.sessionApi.getCurrentSession();

      sessionEither.mapRight((session) => {
        this.setSession(session);
        this.setSessionStatus(SessionStatus.IN_PROGRESS);
      })
    },
    async submitAnswer(params: {
      answerOptionIndex: number;
      answerNumber: number;
    }) {
      const api = useApi();
      const resultStore = useResultStore();

      const submitEither = await api.sessionApi.submitAnswer({
        answerIndex: params.answerOptionIndex,
        answerNumber: params.answerNumber,
        tzOffset: getTimezoneOffset(),
      });

      submitEither.mapRight((submitResult) => {
        resultStore.setResult(submitResult.result);
        this.setSessionStatus(submitResult.sessionStatus);
        this.setSession(submitResult.session);
        this.setIsDeadlinePassed(submitResult.isDeadlinePassed);
      });
    },
    async completeTestTimeOver() {
      const api = useApi()
      await api.sessionApi.completeTestTimeOver();
    },
    setIsDeadlinePassed(isDeadlinePassed: boolean) {
      this.isDeadlinePassed = isDeadlinePassed;
    },
    setSession(session: UserAnsweringSessionEntity) {
      this.session = session;
    },
    setSessionStatus(sessionStatus: SessionStatus) {
      this.sessionStatus = sessionStatus;
    },
  },
});
